import "./App.css";
import { Route, Routes } from "react-router-dom";
import HomePage from "@/pages/HomePage";
import BudgetWidget from "@/components/BudgetWidget";
import LoginPage from "@/pages/LoginPage";
import { PAGE_ROUTES } from "@/constants";
import ResetPasswordPage from "@/pages/ResetPasswordPage";
import ResetLinkPage from "@/pages/ResetLinkPage";
import VerificationCodePage from "@/pages/VerificationCodePage";
import { useAuthStore } from "@/store/authStore.ts";
import AccountLockedPage from "@/pages/AccountLockedPage";
import { ProtectedRoute } from "@/components/ProtectedRoutes/ProtectedRoutes.tsx";
import ContractorDashboardPage from "@/pages/ContractorDashboardPage";
import GISMapPage from "@/pages/GISMapPage";
import UserManagementPage from "@/pages/admin-pages/UserManagementPage";
import UserProfilePage from "@/pages/admin-pages/UserProfilePage";
import AccountManagement from "@/pages/admin-pages/UserManagementPage/components/AccountManagement.tsx";
import RolesManagement from "@/pages/admin-pages/UserManagementPage/components/RolesManagement.tsx";
import AllLogsTable from "@/pages/admin-pages/UserManagementPage/components/AllLogsTable.tsx";
import FileManagementPage from "@/pages/admin-pages/FileManagementPage";
import ProjectsPage from "@/pages/ProjectsPage";
import ProjectDetailsPage from "@/pages/ProjectDetailsPage";
import BudgetPage from "@/pages/BudgetPage";
import FileManagementTable from "@/pages/admin-pages/FileManagementPage/copmonents/FileManagement/FileManagementTable.tsx";
import TemplateManagementTable from "@/pages/admin-pages/FileManagementPage/copmonents/TemplateManagement/TemplateManagementTable.tsx";
import UserFilesManagementTable from "@/pages/admin-pages/FileManagementPage/copmonents/UserFilesManagement/UserFilesManagementTable.tsx";
import NewsPage from "@/pages/NewsPage";
import ProfilePage from "@/pages/ProfilePage";
import PrivacyPolicyPage from "@/pages/PrivacyPolicyPage";
import DataRequestsPage from "@/pages/admin-pages/DataRequestsPage";
import PrivacyPolicyBar from "@/components/PrivacyPolicyBar";
import { useUserStore } from "@/store/userStore.ts";
import { useEffect } from "react";
import NotFoundPage from "@/pages/NotFoundPage";
import { useApplicationStore } from "@/store/applicationStore.ts";

function App() {
  const isAuth = useAuthStore((state) => state.isAuth);
  const userRole = useAuthStore((state) => state.role);
  const trainingCompleted = useAuthStore((state) => state.trainingCompleted);
  const essentialCookies = useAuthStore((state) => state.essentialCookies);
  const fetchIsActive = useApplicationStore((state) => state.fetchIsActive);

  const getMe = useUserStore((state) => state.getMe);
  useEffect(() => {
    if (isAuth) {
      getMe();
      fetchIsActive();
    }
  }, [isAuth]);

  return (
    <>
      {isAuth && !essentialCookies && <PrivacyPolicyBar />}
      <Routes>
        {/*TESTING BUDGET IFRAME ROUTE => */}
        <Route
          path={`/iframe`}
          element={
            <iframe
              src={`${import.meta.env.VITE_APP_URL}/budget-widget`}
              style={{ border: 0, width: "100%", height: "590px" }}
            />
          }
        />
        {/*AUTH ROUTES =>*/}
        <Route path={`/${PAGE_ROUTES.LOGIN}`} element={<LoginPage />} />
        <Route
          path={`/${PAGE_ROUTES.RESET_PASSWORD}`}
          element={<ResetPasswordPage />}
        />
        <Route
          path={`/${PAGE_ROUTES.RESET_PASSWORD}`}
          element={<ResetPasswordPage />}
        />
        <Route
          path={`/${PAGE_ROUTES.RESET_LINK}`}
          element={<ResetLinkPage />}
        />
        <Route
          path={`/${PAGE_ROUTES.VERIFICATION}`}
          element={<VerificationCodePage />}
        />
        <Route
          path={`/${PAGE_ROUTES.ACCOUNT_LOCKED}`}
          element={<AccountLockedPage />}
        />
        {/*<=*/}

        {/*CONTRACTOR ROUTES => */}
        <Route
          element={
            <ProtectedRoute isAllowed={isAuth && userRole === "contractor"} />
          }
        >
          <Route
            element={
              <ProtectedRoute
                redirectTo={`${PAGE_ROUTES.MAP}`}
                isAllowed={!trainingCompleted}
              />
            }
          >
            <Route
              path={`/${PAGE_ROUTES.CONTRACTOR_DASHBOARD}`}
              element={<ContractorDashboardPage />}
            />
          </Route>
          <Route
            element={
              <ProtectedRoute redirectTo={``} isAllowed={trainingCompleted} />
            }
          >
            <Route path={`/${PAGE_ROUTES.MAP}`} element={<GISMapPage />} />
          </Route>
        </Route>
        {/*<=*/}

        {/*ADMIN ROUTES => */}
        <Route
          element={
            <ProtectedRoute
              isAllowed={isAuth && userRole === "administrator"}
            />
          }
        >
          <Route path={``} element={<HomePage />} />
          <Route
            path={`/${PAGE_ROUTES.USER_MANAGEMENT}`}
            element={<UserManagementPage />}
          >
            <Route path={""} element={<AccountManagement />} />
            <Route
              path={`${PAGE_ROUTES.ROLES}`}
              element={<RolesManagement />}
            />
            <Route
              path={`${PAGE_ROUTES.ACCESS_LOGS}`}
              element={<AllLogsTable />}
            />
          </Route>
          <Route
            path={`/${PAGE_ROUTES.USER_PROFILE}/:id`}
            element={<UserProfilePage />}
          />
          <Route
            path={`/${PAGE_ROUTES.DATA_REQUESTS}`}
            element={<DataRequestsPage />}
          />
        </Route>
        {/*<=*/}
        {/*  COMMON ROUTES*/}
        <Route
          element={
            <ProtectedRoute
              isAllowed={
                isAuth &&
                (userRole === "administrator" ||
                  userRole === "oem" ||
                  (userRole === "contractor" && trainingCompleted))
              }
            />
          }
        >
          <Route path={`/${PAGE_ROUTES.BUDGET}`} element={<BudgetPage />} />
          <Route path={`/${PAGE_ROUTES.PROJECTS}`} element={<ProjectsPage />} />
          <Route
            path={`/${PAGE_ROUTES.PROJECTS}/:id`}
            element={<ProjectDetailsPage />}
          />
          <Route
            path={`/${PAGE_ROUTES.FILES_MANAGEMENT}`}
            element={<FileManagementPage />}
          >
            <Route path={""} element={<FileManagementTable />} />
            <Route
              path={`${PAGE_ROUTES.TEMPLATES}`}
              element={<TemplateManagementTable />}
            />
            <Route
              path={`${PAGE_ROUTES.USERS_FILES}`}
              element={<UserFilesManagementTable />}
            />
          </Route>
          <Route
            path={`${PAGE_ROUTES.USER_PROFILE}`}
            element={<ProfilePage />}
          />
          <Route
            path={`${PAGE_ROUTES.PRIVACY_POLICY}`}
            element={<PrivacyPolicyPage />}
          />
        </Route>
        {/*<=*/}
        <Route path={`/budget-widget`} element={<BudgetWidget />} />
        <Route path={`/${PAGE_ROUTES.UPDATES}`} element={<NewsPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
}

export default App;
